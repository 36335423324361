<template>
  <div>
    <div class="horiz">
      <div class="vert hundret gap">
        <div :class="!$vuetify.breakpoint.smAndDown ?  'horiz giantgap' : 'vert gap'">
          <a
            class="admin-head"
            :style="this.$router.currentRoute.name == 'CategoriesDashboard' ? 'text-decoration: underline; color: white;' : 'color: white;'"
            :href="getHref('CategoriesDashboard')"
            @click.left.prevent="navigateToRoute('CategoriesDashboard')"
          >
            Kategorien
          </a>
          <a
            class="admin-head"
            :style="this.$router.currentRoute.name == 'ArticlesDashboard' ? 'text-decoration: underline; color: white;' : 'color: white;'"
            :href="getHref('ArticlesDashboard')"
            @click.left.prevent="navigateToRoute('ArticlesDashboard')"
          >
            Artikel
          </a>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { ArticleDate } from '@/mixins/ArticleDate.mixin.js';
import { Ultra } from '@/mixins/Ultra.mixin.js';
import { Knowledge } from '@/mixins/Knowledge.mixin.js';
import { Navigation } from '@/mixins/Navigation.mixin.js';
import { WarningOverlayMixin } from '@/mixins/WarningOverlayMixin.mixin.js';

export default {
  components: {

  },

  mixins: [
    ArticleDate, Ultra, Knowledge,
    Navigation, WarningOverlayMixin,
  ],

  props: [

  ],

  computed: {

  },

  data: () => ({

  }),

  methods: {

  },

  created() {
    this.requestUObject({
      url: [
        { categories: '' },
        { showWithEntitiesNormalized: '' },
      ],
    });
  },
}
</script>

<style lang="css" scoped>
.v-btn {
  letter-spacing: normal;
  text-transform: none;
}
.vert {
  display: flex;
  flex-direction: column;
}
.vert-block {
  display: block;
  flex-direction: column;
}
.horiz {
  display: flex;
  flex-direction: row;
}
.fifty {
  width: 50%;
}
.hundret {
  width: 100%;
}
.gap {
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}
.biggap {
  grid-row-gap: 30px;
  grid-column-gap: 30px;
}
.giantgap {
  grid-row-gap: 50px;
  grid-column-gap: 50px;
}
.admin-head {
  cursor: pointer;
  text-align: justify;
  font-weight: lighter;
  font-size: 36pt;
  color: white;
}
h1 {
  padding-bottom: 20px;
}
.btn-disabled {
  opacity: .33;
}
.btn-active-up, .btn-active-down {
  opacity: .67;
}
.btn-active-up:hover, .btn-active-down:hover {
  opacity: 1;
  cursor: pointer;
}
</style>
